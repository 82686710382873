<template>
  <div class="app-container">
    <div class="top_select">
      <div>
        <el-input placeholder="请输入公司名称" v-model="listQuery.name" style="width:200px" size="small" clearable @keyup.enter="getList" />
        <selecttaxtype v-model:type="listQuery.type" style="width:140px"></selecttaxtype>
        <el-select
          v-model="listQuery.activestatus"
          placeholder="请选择激活状态"
          filterable
          size="small"
          style="width:140px;margin-left: -1px;"
          clearable
        >
          <el-option
            v-for="item in activestatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button size="small" type="primary" icon="Search" @click="getList" style="margin-right: 5px;">搜索</el-button>
      </div>
      <div style="height: 30px;display: flex;align-items: center;">
        <el-button
          @click="edit({},'more')"
          type="primary"
          size="small"
          >批量修改</el-button
        >
      </div>
    </div>
    <el-table
      :data="list"
      border
      style="width: 100%"
      v-loading="loading"
      :height="contentStyleObj"
      @selection-change="handleSelectionChange"
      stripe
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" type="selection" width="55"> </el-table-column>
      <el-table-column prop="name" label="公司名称" min-width="300"  fixed>
        <template #default="{ row }">
          <TagNameCopy :row="row" :showCopy="false" :needNameJump="false" :showAuthIcon="false" idKey="comId"></TagNameCopy>
        </template>
      </el-table-column>
      <el-table-column prop="appActiveStatus"  label="报税小程序激活状态" align="center" min-width="200">
        <template #default="{ row }">
          <el-tag v-if="row.appActiveStatus === 1" type="success">已激活</el-tag>
          <el-tag v-else type="danger">未激活</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="activeStartTime"  label="购买开始激活时间" align="center" min-width="200">
      </el-table-column>
      <el-table-column prop="activeEndTime"  label="购买结束激活时间" align="center" min-width="200">
        <template #default="{ row }">
          <p
              v-if="row.activeEndTime && row.activeEndTime != '--'"
              :style="{ color: isPastDate(row.activeEndTime) }"
            >
              {{ row.activeEndTime }}
            </p>
        </template>
      </el-table-column>
      <el-table-column prop="oneYearVipPrice"  label="一年价格（元）" align="center" min-width="200">
      </el-table-column>
      <el-table-column prop="permanentVipPrice"  label="终身价格（元）" align="center" min-width="200">
      </el-table-column>
      <el-table-column prop="count" label="操作" align="center" min-width="120">
        <template #default="{ row }">
          <el-button
            link
            size="small"
            type="text"
            @click="edit(row,'one')"
            >编辑</el-button>
          <el-button
            link
            size="small"
            type="text"
            @click="cencelJh(row)"
            >取消激活</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div class="pagination">
    <qzf-pagination
      v-show="total > 0"
      :total="total"
      v-model:page="listQuery.page"
      v-model:limit="listQuery.pageSize"
      @pagination="getList"
    />
  </div>
  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    :title="type == 'more'? '批量修改':'编辑'"
    v-model="addShow"
    width="21%"
  >
    <el-form size="small" :model="form" :rules="rules" ref="ruleFormRef" label-position="right"   label-width="80px">
      <el-form-item label="公司名称" prop="name"  v-if="type != 'more'">
        <el-input v-model="form.name" style="width:240px;margin-left: 0;" disabled/>
      </el-form-item>
      <el-form-item label="纳税人性质" prop="type" v-if="type != 'more'">
        <selecttaxtype v-model:type="form.type" style="width:240px;margin-left: 0;" disabled></selecttaxtype>
      </el-form-item>
      <el-form-item label="一年价格" prop="oneYearVipPrice">
        <el-input v-model.trim="form.oneYearVipPrice" @blur="changeOne(form.oneYearVipPrice)" style="width: 240px;margin-left: 0;">
          <template #append>元</template>
        </el-input> 
      </el-form-item>
      <el-form-item label="终身价格" prop="permanentVipPrice">
        <el-input v-model.trim="form.permanentVipPrice" @blur="changeMore(form.permanentVipPrice)" style="width: 240px;margin-left: 0;">
          <template #append>元</template>
        </el-input> 
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="addShow = false"
          >取 消</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="save"
          :loading="saveLoading"
          >保存</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, onMounted,getCurrentInstance } from "vue";
import { ElMessage,ElMessageBox } from "element-plus";
import { xcxComPaymentList,updateTaxAppComVipPrice,cancelActive } from "@/api/company"
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";
import selecttaxtype from "@/components/Screening/selecttaxtype";
const { proxy } = getCurrentInstance();
const ruleFormRef = ref();
const loading = ref(false)
const addShow = ref(false)
const saveLoading = ref(false)
const total = ref(0)
const list = ref([])
const sels = ref([])
const ids = ref([])
const type = ref('')
const form = ref({})
const rules = ref({
  oneYearVipPrice: [
    { required: true, message: "请输入标题", trigger: "blur" },
    { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '请输入正确的格式,可保留两位小数' }
  ],
  permanentVipPrice: [
    { required: true, message: "请输入广告内容", trigger: "blur" },
    { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '请输入正确的格式,可保留两位小数' }
  ],
});
const listQuery = ref({
  page: 1,
  limit: 20,
  name:'',
  activestatus:'2',
})
const activestatusList = ref([
{
    value: '2',
    label: '全部',
  },
  {
    value: '0',
    label: '未激活',
  },
  {
    value: '1',
    label: '已激活',
  },
]);

const contentStyleObj = ref({});//列表高度

onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(250);
  getList();
});
const changeOne = (e) => {
  let amount = e*1
  form.value.oneYearVipPrice = amount.toFixed(2).toString()
}
const changeMore = (e) => {
  let amount = e*1
  form.value.permanentVipPrice = amount.toFixed(2).toString()
}
const getList = () => {
  loading.value = true;
  xcxComPaymentList(listQuery.value).then((res) =>{
    console.log(res);
    loading.value = false;
    if(res.data.msg == 'success'){
      list.value = res.data.data.list
      total.value = res.data.data.total
    }
  })
};
const edit = (val,ty) =>{
  reset()
  if(ty == 'more'){
    if (sels.value.length == 0) {
      ElMessage.warning("请选择至少一条数据");
      return;
    }
  }
  addShow.value = true
  type.value = ty
  form.value = Object.assign({},val);
}

const handleSelectionChange = (e) =>{
  sels.value = e;
  ids.value = sels.value.map((v) => v.id)
}
const reset = () =>{
  form.value = {
    oneYearVipPrice:'',
    permanentVipPrice:'',
  }
}
const save = () =>{
  ruleFormRef.value.validate((valid) => {
    if (valid) {
      saveLoading.value = true;
      form.value.comIds = []
      if(type.value=='more'){
        form.value.comIds = ids.value
      }else{
        form.value.comIds = [form.value.id]
      }
      updateTaxAppComVipPrice(form.value).then((res) =>{
        console.log(res);
        saveLoading.value = false;
        if(res.data.msg == 'success'){
          ElMessage.success('修改成功');
          addShow.value = false;
          getList()
        }
      })
    }
  });
}
const isPastDate = (time) => {
  let today = new Date();
  let futureDate = new Date(today.getTime() + 16 * 24 * 60 * 60 * 1000);
  let year = futureDate.getFullYear();
  let month = (futureDate.getMonth() + 1).toString().padStart(2, "0"); // padStart()方法在月份和日期不足两位数时填充0
  let day = futureDate.getDate().toString().padStart(2, "0");
  let ago = year + "-" + month + "-" + day;
  return time < ago ? "red" : "black";
};
// 取消激活
const cencelJh = (val) => {
  if(val.appActiveStatus == 0){
    ElMessage.warning("该公司未激活");
    return;
  }
  let params ={
    comIds:[val.id]
  }
  ElMessageBox.confirm(
    '确定要取消激活吗？取消后，该公司将无法享受专属权益!',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: '确定',
    }
  )
  .then(() => {
    cancelActive(params).then((res) =>{
      console.log(res);
      if(res.data.msg == 'success'){
        ElMessage.success('修改成功');
        getList()
      }
    })
  })
  .catch(() => {
  })
};


</script>

<style scoped lang="scss">
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.w250{
  width: 250px;
}
.pagination {
  margin-top: 16px;
  float: right;
}
</style>
